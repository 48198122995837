<template>
  <div class="register-page">
    <div class="section">
      <div class="container is-mini p-t-4x">
        <h1 class="is-size-3 has-text-primary has-text-weight-bold has-text-centered m-b-5x">
          {{ $t('views.admin.Admin Login') }}
        </h1>
        <LoginForm />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from './LoginForm'

export default {
  name: 'Login',
  components: { LoginForm },
  metaInfo () {
    return {
      title: 'Login'
    }
  }
}
</script>

<style scoped>

</style>
