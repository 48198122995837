<template>
  <div class="register-page">
    <div class="section">
      <div class="container is-mini p-t-4x">
        <h1 class="is-size-3 has-text-primary has-text-weight-bold has-text-centered m-b-5x">
          {{ $t('views.admin.Admin Register') }}
        </h1>
        <el-form
          ref="registerForm"
          :model="registerForm"
          :rules="rules"
          class="columns is-multiline is-mini-gap"
          label-position="top"
          status-icon>
          <el-form-item
            :label="$t('views.customer.form.first-name.label')"
            class="column is-6"
            prop="firstName">
            <el-input
              v-model.trim="registerForm.firstName"
              :placeholder="$t('views.customer.form.first-name.placeholder')"
              autocomplete="firstName" />
          </el-form-item>
          <el-form-item
            :label="$t('views.customer.form.last-name.label')"
            class="column is-6"
            prop="lastName">
            <el-input
              v-model.trim="registerForm.lastName"
              :placeholder="$t('views.customer.form.last-name.placeholder')"
              autocomplete="lastName" />
          </el-form-item>
          <el-form-item
            :label="$t('views.customer.form.email.label')"
            class="column is-12"
            prop="email">
            <el-input
              v-model.trim="registerForm.email"
              :placeholder="$t('views.customer.form.email.placeholder')"
              type="email"
              autocomplete="email"
              disabled />
          </el-form-item>
          <el-form-item
            class="column is-12"
            label="Password"
            prop="password">
            <template #label>
              {{ $t('views.customer.form.password.label') }}
              <a
                class="toggle-password has-text-secondary is-pulled-right"
                @click="togglePassword">{{ togglePasswordText }}</a>
            </template>
            <el-input
              v-model.trim="registerForm.password"
              :type="passwordType"
              autocomplete="off"
              @input="handleValidRetype" />
          </el-form-item>
          <el-form-item
            :label="$t('views.customer.form.retype-password.label')"
            class="column is-12 m-b-4x"
            prop="retypePassword">
            <el-input
              v-model.trim="registerForm.retypePassword"
              :type="passwordType"
              autocomplete="off" />
          </el-form-item>
          <div class="column is-12 recaptcha-column">
            <Recaptcha
              ref="recaptcha"
              @verify="handleRecaptchaVerify"
              @error="handleRecaptchaError" />
          </div>
          <div
            v-if="error"
            class="column is-12">
            <el-alert
              :title="error"
              :closable="false"
              type="error" />
          </div>
          <div class="column is-12">
            <el-button
              :loading="isSubmitting"
              class="button-secondary"
              style="min-width: 155px;"
              @click="handleRegister">
              {{ $t('views.TheHeader.register') }}
            </el-button>
          </div>
          <div class="column is-12">
            <RecaptchaTerms />
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import mixPassword from '@/views/customer/mixins/password'
import Recaptcha from '@/components/Recaptcha'
import RecaptchaTerms from '@/components/RecaptchaTerms'

export default {
  name: 'Register',
  components: { RecaptchaTerms, Recaptcha },
  mixins: [mixPassword],
  data () {
    return {
      registerForm: {
        firstName: undefined,
        lastName: undefined,
        email: 'jbrown@company.com',
        password: undefined,
        retypePassword: undefined
      },
      isSubmitting: false,
      error: '',
      rules: {
        firstName: [
          {
            required: true,
            message: this.$t('views.customer.form.first-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        lastName: [
          {
            required: true,
            message: this.$t('views.customer.form.last-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        password: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change', 'blur'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.password.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        retypePassword: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change', 'blur'
            ]
          }, {
            validator: (rule, value, callback) => {
              if (value !== this.registerForm.password) {
                callback(new Error(this.$t('views.customer.form.retype-password.invalid')))
              } else {
                callback()
              }
            },
            trigger: [
              'change', 'blur'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.retype-password.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      }
    }
  },
  created () {
    const { email, firstName, lastName, actionToken } = this.$route.query
    this.registerForm.firstName = firstName
    this.registerForm.lastName = lastName
    this.registerForm.email = email
    this.registerForm.token = actionToken
  },
  methods: {
    handleRegister () {
      this.error = ''
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.isSubmitting = true
          this.$refs.recaptcha.execute()
        }
      })
    },
    handleValidRetype () {
      if (this.registerForm.retypePassword !== undefined) {
        this.$refs.registerForm.validateField('retypePassword')
      }
    },
    handleRecaptchaVerify (token) {
      this.$store.dispatch('admin/registerUser', {
        data: this.registerForm,
        headers: {
          'x-recaptcha-token': token,
          'x-recaptcha-ver': 'V2_INVISIBLE'
        }
      }).then(({ outlets }) => {
        this.isSubmitting = false
        this.$refs.recaptcha.reset()
        if (outlets.details) {
          this.$router.push({ name: 'admin/login' })
        }
      }).catch((err) => {
        this.isSubmitting = false
        this.$refs.recaptcha.reset()
        this.$store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    },
    handleRecaptchaError (message) {
      this.error = message
      this.isSubmitting = false
      this.$refs.recaptcha.reset()
    }
  },
  metaInfo () {
    return {
      title: 'Register'
    }
  }
}
</script>

<style scoped>

</style>
