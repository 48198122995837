<template>
  <div class="register-page">
    <div
      class="section"
      :class="titleSectionClass">
      <div
        class="container is-tablet  has-text-centered"
        :class="titleContainerClass">
        <h1
          class="title is-size-3 m-b"
          :class="titleClass">
          {{ $t('views.customer.ResetPasswordSuccess.title') }}
        </h1>
        <p>{{ $t('views.customer.ResetPasswordSuccess.desc') }}</p>
      </div>
    </div>
    <div class="section">
      <div
        class="container"
        :class="formContainerClass">
        <component :is="loginForm" />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from './LoginForm'
import AdminLogin from '@/views/admin/LoginForm'

export default {
  name: 'ResetPasswordSuccess',
  components: {
    LoginForm,
    'AdminLogin': AdminLogin
  },
  computed: {
    loginForm () {
      return this.$route.meta.isAdminRoute ? 'AdminLogin' : 'LoginForm'
    },
    titleSectionClass () {
      return this.$route.meta.isAdminRoute ? [] : ['has-background-primary', 'has-text-white']
    },
    titleClass () {
      return this.$route.meta.isAdminRoute ? ['has-text-primary'] : ['has-text-white']
    },
    titleContainerClass () {
      return this.$route.meta.isAdminRoute ? ['p-t-4x'] : ['p-t-10x']
    },
    formContainerClass () {
      return this.$route.meta.isAdminRoute ? ['is-mini'] : ['is-mobile', 'p-t-4x']
    }
  },
  metaInfo () {
    return {
      title: 'Password Reset Successful'
    }
  }
}
</script>

<style scoped>

</style>
