<template>
  <el-form
    ref="forgotForm"
    :model="forgotForm"
    :rules="rules"
    class="columns is-multiline"
    label-position="top"
    status-icon
    @submit.native="handleForgot">
    <el-form-item
      :label="$t('views.customer.form.email.label')"
      class="column is-12 m-b-4x"
      prop="email">
      <el-input
        v-model.trim="forgotForm.email"
        type="email"
        autocomplete="email" />
    </el-form-item>

    <div class="column is-12 recaptcha-column">
      <Recaptcha
        ref="recaptcha"
        @verify="handleRecaptchaVerify"
        @error="handleRecaptchaError" />
    </div>
    <div
      v-if="error"
      class="column is-12">
      <el-alert
        :title="error"
        :closable="false"
        type="error" />
    </div>

    <div class="column is-12">
      <el-button
        :loading="isSubmitting"
        class="button-secondary"
        style="min-width: 155px;"
        native-type="submit">
        {{ $t('views.customer.form.submit') }}
      </el-button>
    </div>
    <div class="column is-12">
      <RecaptchaTerms />
    </div>
  </el-form>
</template>

<script>
import Recaptcha from '@/components/Recaptcha'
import RecaptchaTerms from '@/components/RecaptchaTerms'

export default {
  name: 'ForgotForm',
  components: { RecaptchaTerms, Recaptcha },
  data () {
    return {
      isSubmitting: false,
      error: '',
      forgotForm: {
        email: undefined
      },
      rules: {
        email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      }
    }
  },

  methods: {
    handleForgot (e) {
      e.preventDefault()
      this.error = ''
      this.$refs.forgotForm.validate((valid) => {
        if (valid) {
          this.isSubmitting = true
          this.$refs.recaptcha.execute()
        }
      })
    },
    handleRecaptchaVerify (token) {
      this.$store.dispatch('admin/forgotPassword', {
        data: this.forgotForm,
        headers: {
          'x-recaptcha-token': token,
          'x-recaptcha-ver': 'V2_INVISIBLE'
        }
      }).then(
        ({ outlets }) => {
          this.isSubmitting = false
          this.$refs.recaptcha.reset()
          this.$emit('success', { email: this.forgotForm.email, outlets })
        }
      ).catch((err) => {
        this.isSubmitting = false
        this.$refs.recaptcha.reset()
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    },
    handleRecaptchaError (message) {
      this.error = message
      this.isSubmitting = false
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style scoped>

</style>
