<template>
  <div class="register-page">
    <div class="section">
      <div class="container is-mini p-t-4x">
        <h1 class="is-size-3 has-text-primary has-text-weight-bold has-text-centered m-b-2x">
          Reset your password
        </h1>
        <div
          v-if="!isSuccess"
          class="m-b-5x has-text-centered">
          Enter your email address below to receive a new password reset link to your email.
        </div>
        <ForgotForm
          v-if="!isSuccess"
          class="admin-forgot-form"
          @success="handleSuccess" />
        <el-alert
          v-if="isSuccess"
          :title="$t('views.customer.CheckEmail.title')"
          :closable="false"
          type="success"
          show-icon>
          <template name="description">
            <p v-html="$t('views.customer.CheckEmail.message', { email })" />
          </template>
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>

import ForgotForm from '@/views/admin/ForgotForm'

export default {
  name: 'ForgotPassword',
  components: { ForgotForm },
  data () {
    return {
      isSuccess: false,
      email: undefined
    }
  },
  metaInfo () {
    return {
      title: 'Reset Password'
    }
  },
  methods: {
    handleSuccess ({ email, outlets }) {
      if (outlets.ok) {
        this.email = email
        this.isSuccess = true
      } else {
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          content: this.$t('views.customer.ForgotPassword.emailNotFound')
        })
      }
    }
  }
}
</script>

<style>

</style>
