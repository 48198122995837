<template>
  <el-form
    ref="loginForm"
    :model="loginForm"
    :rules="rules"
    class="columns is-mini-gap is-multiline"
    label-position="top"
    status-icon
    @submit.native="handleLogin">
    <el-form-item
      :label="$t('views.customer.form.email.label')"
      class="column is-12"
      prop="email">
      <el-input
        v-model.trim="loginForm.email"
        type="email"
        autocomplete="email" />
    </el-form-item>
    <el-form-item
      :label="$t('views.customer.form.password.label')"
      class="column is-12 m-b-4x"
      prop="password">
      <template #label>
        {{ $t('views.customer.form.password.label') }}
        <span
          class="toggle-password has-text-secondary is-pulled-right"
          @click="togglePassword">{{ togglePasswordText }}</span>
      </template>
      <el-input
        v-model.trim="loginForm.password"
        :type="passwordType"
        autocomplete="off" />
    </el-form-item>
    <div class="column is-12 recaptcha-column">
      <Recaptcha
        ref="recaptcha"
        @verify="handleRecaptchaVerify"
        @error="handleRecaptchaError" />
    </div>
    <div
      v-if="loginErr.show"
      class="error-box">
      <el-alert
        :title="loginErr.message"
        :closable="false"
        type="error" />
    </div>
    <div class="column is-12">
      <el-button
        :loading="isLoading"
        class="button-secondary m-r-3x"
        style="min-width: 155px;"
        native-type="submit">
        {{ $t('views.customer.form.login') }}
      </el-button>
      <RouterLink
        :to="{name:'admin/forgot-password'}"
        class="has-text-secondary">
        {{ $t('views.customer.form.forgot-password') }}
      </RouterLink>
    </div>
    <div class="column is-12">
      <RecaptchaTerms />
    </div>
  </el-form>
</template>

<script>
import mixPassword from '@/views/customer/mixins/password'
import RecaptchaTerms from '@/components/RecaptchaTerms'
import Recaptcha from '@/components/Recaptcha'

export default {
  name: 'LoginForm',
  components: { Recaptcha, RecaptchaTerms },
  mixins: [mixPassword],
  data () {
    return {
      isLoading: false,
      loginErr: {
        show: false,
        message: ''
      },
      loginForm: {
        email: undefined,
        password: undefined
      },
      rules: {
        email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        password: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change', 'blur'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.password.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      }
    }
  },

  methods: {
    handleLogin (e) {
      e.preventDefault()
      this.loginErr.show = false
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.isLoading = true
          this.$refs.recaptcha.execute()
        }
      })
    },
    handleRecaptchaVerify (token) {
      return this.$store.dispatch('admin/login', {
        data: this.loginForm,
        headers: {
          'x-recaptcha-token': token,
          'x-recaptcha-ver': 'V2_INVISIBLE'
        }
      }).then(({ outlets, meta }) => {
        this.isLoading = false
        this.$refs.recaptcha.reset()
        const href = this.$router.resolve({ name: 'admin/client-management' }).href
        window.location = href
      }).catch(err => {
        console.log(err)
        this.isLoading = false
        this.$refs.recaptcha.reset()
        if (err.error && err.error.type === 'invalid_request_error') {
          this.$store.commit('SET_GLOBAL_MODAL', {
            show: true,
            content: err.message || err.error.message
          })
        }
        if (err.error && err.error.type === 'authentication_error') {
          this.loginErr.show = true
          this.loginErr.message = err.error.message
        }
        // throw err Do not display other login errors or throw exceptions
      })
    },
    handleRecaptchaError (message) {
      this.loginErr.show = true
      this.loginErr.message = message
      this.isLoading = false
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style scoped>

</style>
